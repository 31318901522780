/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7ybjjrftkbc5lfh65hhgdwn2uy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bptvy4fhtjg5boumgyb3rctosu",
    "aws_cognito_identity_pool_id": "us-east-1:8a94d50d-48b4-4cb7-ba8e-79aa14d04a86",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3f3BGi2oB",
    "aws_user_pools_web_client_id": "21uftao1ivjugut4m7jigpfcca",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "huellasmartlite4df45581e6fc4b81be31341cbf4c9c93ce023-produccion",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
