// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Company, DeclaracionEnfoque, User, CompanyAdmin, Emision, Factor, Establishment, Address  } = initSchema(schema);

export {
  Company,
  DeclaracionEnfoque,
  User,
  CompanyAdmin,
  Emision,
  Factor,
  Establishment,
  Address
};